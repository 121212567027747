import * as React from "react";
import { LobSubtitleWithLinkProps } from "./typings";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSSubheading } from "@egds/react-core/text";
import { EGDSLink } from "@egds/react-core/link";
import { TrackedLink } from "src/components/utility/analytics/TrackedLink";
import { Action } from "src/components/utility/analytics/FlexAnalyticsUtils";

export const LobSubtitleWithLink = ({ linkUrl, locKey, locLinkKey, className }: LobSubtitleWithLinkProps) => {
  const { formatText } = useLocalization();
  const text = formatText(locKey);
  const linkText = formatText(locLinkKey);

  return (
    <div className={className} data-testid="lobsubtitle">
      <EGDSSubheading>
        {text}{" "}
        <EGDSLink inline>
          <TrackedLink
            target="_blank"
            moduleName="LobSubtitleWithLink"
            action={Action.CLICK}
            href={linkUrl}
            rfrr="subtitle.link"
          >
            <span className="is-visually-hidden">{formatText("opens.in.new.window")}</span>
            {linkText}
          </TrackedLink>
        </EGDSLink>
      </EGDSSubheading>
    </div>
  );
};
